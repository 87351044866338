var searchKeys = [
// {
//     key: "type",
//     label: "类目",
//     placeholder: '请选择',
//     rules: [],
//     select: true
//   },
{
  key: "name",
  label: "商品名称",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };