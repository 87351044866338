var columns = [{
  title: '素材ID',
  dataIndex: 'id',
  key: 'id',
  // width: '10%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '素材类目',
  dataIndex: 'parenttype',
  key: 'parenttype',
  // width: '14%',
  scopedSlots: {
    customRender: 'parenttype'
  }
}, {
  title: '素材名称',
  dataIndex: 'name',
  key: 'name',
  // width: '10%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '素材预览',
  dataIndex: 'linkurl',
  key: 'linkurl',
  // width: '14%',
  scopedSlots: {
    customRender: 'linkurl'
  }
}, {
  title: '价格',
  dataIndex: 'payamount',
  key: 'payamount',
  // width: '20%',
  scopedSlots: {
    customRender: 'payamount'
  }
}, {
  title: '售卖单位',
  dataIndex: 'paytype',
  key: 'paytype',
  // width: '11%',
  scopedSlots: {
    customRender: 'paytype'
  }
}, {
  title: '创建时间',
  dataIndex: 'createtime',
  key: 'createtime',
  // width: '10%',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  // fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };