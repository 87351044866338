import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "release"
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "素材名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请输入素材名称"
        }]
      }],
      expression: "[\n                    'name',\n                    {\n                        rules: [\n                            { required: true, message: '请输入素材名称' },\n                        ],\n                    },\n                ]"
    }],
    on: {
      change: function change(e) {
        return e.target.value = e.target.value.trim();
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "素材类目"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["parentname", {
        rules: [{
          required: true,
          message: "请选择"
        }]
      }],
      expression: "[\n                    'parentname',\n                    {\n                        rules: [{ required: true, message: '请选择' }],\n                    },\n                ]"
    }],
    attrs: {
      allowClear: "",
      "show-search": "",
      disabled: _vm.disabled,
      "filter-option": _vm.filterOption,
      placeholder: "请选择素材类目",
      "option-label-prop": "label"
    }
  }, _vm._l(_vm.typeList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _vm.linkurl ? _c("a-form-item", {
    staticClass: "coverImg",
    attrs: {
      label: "素材示意图"
    }
  }, [_c("img", {
    staticStyle: {
      width: "120px",
      height: "120px"
    },
    attrs: {
      src: _vm.linkurl,
      alt: ""
    }
  })]) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: "价格"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["payamount", {
        rules: [{
          required: true,
          message: "请输入价格"
        }]
      }],
      expression: "[\n                    'payamount',\n                    {\n                        rules: [\n                            {\n                                required: true,\n                                message: '请输入价格',\n                            },\n                        ],\n                    },\n                ]"
    }],
    attrs: {
      type: "number"
    },
    on: {
      change: function change(e) {
        return e.target.value = e.target.value.trim();
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "售卖单位"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["paytype", {
        rules: [{
          required: true,
          message: "请选择"
        }]
      }],
      expression: "[\n                    'paytype',\n                    {\n                        rules: [{ required: true, message: '请选择' }],\n                    },\n                ]"
    }],
    attrs: {
      allowClear: "",
      "show-search": "",
      "filter-option": _vm.filterOption,
      placeholder: "请选择奖励",
      "option-label-prop": "label"
    }
  }, _vm._l(_vm.payType, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1), _c("div", {
    staticClass: "footer"
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["infoManagement:btn:issue"],
      expression: "['infoManagement:btn:issue']"
    }],
    staticClass: "btn_l",
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v("提交 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };